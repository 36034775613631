<template>
    <div></div>
</template>
<script>
export default {
    name:'Reload',
    data(){
        return{

        }
    },
    mounted(){
        let navBgSwitch=this._storeData('navBgSwitch')
        if(this.IsNull(navBgSwitch)!=''){
            navBgSwitch(false)
        }

        let reload= this._storeData('reload')
        this._storeData('reload','')
        this.Clog(reload)
        
        if(this.IsNull(reload)!=''){
            reload();
        }else{
            this._go('/')
        }
        this._storeData('reload','')
        
    },
    methods:{

    }
}
</script>